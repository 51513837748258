<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="#0086FC"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="11"
      cy="11"
      r="10"
      fill="transparent"
      stroke="currentColor"
      stroke-width="2"
    />
    <circle cx="11" cy="11" r="5" fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: "DotIcon",
  props: {
    value: {
      default: false,
    },
  },
};
</script>
