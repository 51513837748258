<template>
  <div class="container">
    <div class="order-form">
      <div class="row">
        <div class="col-12 col-md order-box">
          <div class="order-mobile-box">
            <div class="title">Оформление заказа</div>
            <!--                        <div class="title-help">
                                        Выберите колонну и куб. Все доп. товары и
                                        модификации куба и колонны можно заказать с
                                        аппаратом или позже.
                                    </div>-->
          </div>
          <!--                    <div class="order-size" v-if="baseItem">
                                  <div
                                      class="row align-items-center justify-content-center"
                                  >
                                      <div class="col-auto">
                                          <img
                                              v-lazy="{
                                                  src: require('../assets/size.png'),
                                              }"
                                              alt=""
                                              class="image-size"
                                          />
                                      </div>
                                      <div class="col order-size-title d-none d-md-block">
                                          {{ baseItem.name }}
                                          <Transition>
                                              <div
                                                  class="up-help"
                                                  v-if="baseItem.count > 1"
                                              >
                                                  На изображении показан один модуль.
                                              </div>
                                          </Transition>
                                      </div>
                                      <div class="col-auto">
                                          <div class="order-size-title d-md-none">
                                              {{ baseItem.name }}
                                          </div>
                                          <div class="select-size">
                                              <div class="row align-items-center">
                                                  <div
                                                      class="col-auto"
                                                      v-if="baseItem.count > 0"
                                                  >
                                                      <button
                                                          @click="decrementItem(baseItem)"
                                                      >
                                                          -
                                                      </button>
                                                  </div>
                                                  <div
                                                      class="col size-value"
                                                      v-if="baseItem.count > 0"
                                                  >
                                                      {{ baseItem.count }} шт.
                                                  </div>
                                                  <div
                                                      class="col add-text"
                                                      @click="incrementItem(baseItem)"
                                                      v-if="baseItem.count == 0"
                                                  >
                                                      Добавить
                                                  </div>
                                                  <div class="col-auto">
                                                      <button
                                                          @click="incrementItem(baseItem)"
                                                      >
                                                          +
                                                      </button>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>-->
          <!--                    <div class="order-image" v-if="mobile">
                                  <canvas-editor
                                      :cart="cart"
                                      :items="items"
                                      :base-id="100"
                                  />
                              </div>-->
          <div v-if="mobile">
            <div class="mb-4 mobile-delivery-point" v-if="deliveryPoint">
              <div class="row mx-0 align-items-center">
                <div class="col-auto ps-0">
                  <img
                    v-lazy="{
                      src: require('../assets/car.svg'),
                    }"
                    alt=""
                    style="max-height: 25px"
                  />
                </div>
                <div class="col px-0">
                  На пункт выдачи:
                  <span v-if="deliveryPoint.terminal">
                    {{ deliveryPoint.terminal.city_abbr }}
                    {{ deliveryPoint.terminal.city_name }},
                    {{ deliveryPoint.terminal.street_abbr }}
                    {{ deliveryPoint.terminal.street }}
                    {{ deliveryPoint.terminal.house_no }}
                  </span>
                  <span v-if="deliveryPoint.office">{{
                    deliveryPoint.office.address.fullAddress
                  }}</span>
                </div>
              </div>
            </div>
            <div class="order-promo dashed" v-if="promo.code && promoActive">
              <div class="promo-title">
                Активирован промокод
                <span class="code">{{ promo.code }}</span>
              </div>
              <!--                            <div>
                                                  <ul>
                                                      <li
                                                          v-for="gift in promo.gift_items"
                                                          :key="'gift_' + gift.id"
                                                      >
                                                          <span class="item-title">{{
                                                              gift.name
                                                          }}</span>
                                                          в подарок
                                                      </li>
                                                  </ul>
                                              </div>-->
            </div>
            <div class="order-image">
              <div class="image-container">
                <canvas-editor
                  ref="canvasMobile"
                  :cart="cart"
                  :items="items"
                  :base-id="baseId"
                />
              </div>
              <div class="order-left-box">
                <!--                <div class="mobile-select-core-items mb-4">
                  <div class="row mx-0 justify-content-between">
                    <div class="col-auto col-md px-0">
                      <div
                        class="row align-items-center"
                        @click="toggleItem(columnItem)"
                      >
                        <div class="col-auto d-flex pe-1">
                          <check-icon
                            v-if="!columnItem.is_radio"
                            class="order-check checkbox me-1"
                            :class="{
                              checked: columnItem.active,
                            }"
                          />
                        </div>
                        <div class="col-auto ps-0 pe-1">
                          {{ columnItem.name.short }}
                        </div>
                        <div class="col-auto px-0 order-list-price fw-bold">
                          {{ parsePrice(getVariant(columnItem.id).price) }} ₽
                        </div>
                      </div>
                      <div class="sw-padding">
                        <switch-select
                          :variants="columnItem.variants"
                          :item="columnItem"
                          :disabled="!columnItem.active"
                          @click="addItem(columnItem)"
                        />
                      </div>
                    </div>
                    <div class="col-auto col-md px-0">
                      <div
                        class="row align-items-center"
                        @click="toggleItem(tankItem)"
                      >
                        <div class="col-auto d-flex pe-1">
                          <check-icon
                            v-if="!tankItem.is_radio"
                            class="order-check checkbox me-1"
                            :class="{
                              checked: tankItem.active,
                            }"
                          />
                        </div>
                        <div class="col-auto ps-0 pe-1">
                          {{ tankItem.name.short }}
                        </div>
                        <div
                          class="col-auto ps-0 pe-2 order-list-price fw-bold"
                        >
                          {{ parsePrice(getVariant(tankItem.id).price) }} ₽
                        </div>
                      </div>
                    </div>
                  </div>
                </div>-->
                <div class="order-list">
                  <!-- eslint-disable -->
                    <div v-for="item in sortedItems" :key="item.id">
                        <div
                            v-if="item.display"
                            class="row mx-0 order-list-item"
                            :class="{'fs-min': item.subitem, 'mt-item': !item.subitem, 'mt-base': !item.subitem, 'ml-check mt-2': item.subitem, 'mt-base': !item.subitem}"
                        >
                                            <!-- eslint-enable -->
                      <div class="col-auto px-0">
                        <dot-icon
                          v-if="item.is_radio"
                          class="order-check radio"
                          :class="{
                            checked: item.active,
                          }"
                          @click="toggleItem(item)"
                        />
                        <check-icon
                          v-if="!item.is_radio"
                          class="order-check checkbox"
                          :class="{
                            checked: item.active,
                          }"
                          @click="toggleItem(item)"
                        />
                      </div>
                      <div class="col px-0">
                        <a
                          href="javascript:void(0)"
                          class="order-list-title"
                          v-if="item.subitem"
                          @click.prevent.stop="showItem(item)"
                          v-html="parseName(item)"
                        >
                        </a>
                        <span
                          class="order-list-title up"
                          v-if="!item.subitem"
                          @click="toggleItem(item)"
                          v-html="parseName(item)"
                        >
                        </span>

                        <switch-select
                          class="ps-2"
                          v-if="item.variants"
                          :variants="item.variants"
                          :item="item"
                          :disabled="!item.active"
                          @click="addItem(item)"
                        />
                        <!--                                            <span
                                                                            v-if="!item.subitem"
                                                                            class="order-list-title up"
                                                                            v-html="
                                                                                parseName(item, 'short')
                                                                            "
                                                                        >
                                                                        </span>-->
                      </div>
                      <div class="col-auto px-0">
                        <div @click="toggleItem(item)">
                          <div
                            v-if="markAsGift.includes(item.id)"
                            class="order-list-free"
                          >
                            <img src="../assets/gift.svg" alt="" />
                            {{ freeText }}
                          </div>
                          <div
                            class="order-list-price fw-bold"
                            :class="{
                              // 'fw-bold': !item.subitem,
                            }"
                            v-else
                          >
                            <!--                            <span v-if="item.subitem">+</span>-->
                            {{ parsePrice(getVariant(item.id).price) }}
                            ₽
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--                                <div
                                                          v-if="baseItem"
                                                          class="row mx-0 order-list-item"
                                                          @click="toggleBaseItem(baseItem)"
                                                      >
                                                          &lt;!&ndash; eslint-enable &ndash;&gt;
                                                          <div class="col-auto px-0">
                                                              <img
                                                                  v-lazy="{
                                                                      src: require('../assets/check.svg'),
                                                                  }"
                                                                  :class="{
                                                                      checked: baseItem.count > 0,
                                                                  }"
                                                                  class="order-check"
                                                                  alt=""
                                                              />
                                                          </div>
                                                          <div class="col px-0">
                                                              <a
                                                                  href="javascript:void(0)"
                                                                  class="order-list-title"
                                                                  @click.prevent.stop="
                                                                      showItem(baseItem)
                                                                  "
                                                              >
                                                                  {{ baseItem.short }}
                                                              </a>
                                                              <div
                                                                  class="order-list-price"
                                                                  v-if="!baseItem.gift"
                                                              >
                                                                  {{ parsePrice(baseItem.price) }}
                                                                  ₽
                                                              </div>
                                                              <div v-else class="order-list-free">
                                                                  {{ freeText }}
                                                              </div>
                                                          </div>
                                                      </div>-->
                </div>
              </div>
            </div>
            <div class="order-mobile-box">
              <div>
                <div
                  v-if="orderDiscountPrice <= 0"
                  class="mt-3 mb-1 px-5 text-center"
                >
                  Для оформления заказа выберите
                  <a href="javascript:void(0)" @click="toggleItemId(columnId)"
                    >колонну</a
                  >
                  или
                  <a href="javascript:void(0)" @click="toggleItemId(tankId)"
                    >куб</a
                  >. Либо закажите товар отдельно, нажав на его название.
                </div>
              </div>
              <div class="order-price w-100" v-if="orderFullPrice > 0">
                <div class="row align-items-center mt-3 mb-1">
                  <!--                  <div class="col-12">
                                        <div class="all-title">Итого:</div>
                                      </div>-->
                  <div class="col" v-if="orderDiscountPrice > 0">
                    <div
                      class="help"
                      v-if="orderFullPrice != orderDiscountPrice"
                    >
                      Скидка действует при заказе {{ dateNow }}!
                    </div>
                  </div>
                  <div class="col-auto">
                    <div class="text-end">
                      <div>
                        <div
                          class="old-value"
                          v-if="orderFullPrice != orderDiscountPrice"
                        >
                          {{ parsePrice(orderFullPrice) }} ₽
                        </div>
                      </div>
                      <div>
                        <span class="value"
                          >{{ parsePrice(orderDiscountPrice) }} ₽</span
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="order-form-action">
                  <div class="row">
                    <div class="col col-md-6 px-1 px-md-3">
                      <input
                        type="text"
                        v-maska="mask"
                        v-model="form.phone"
                        :disabled="ordering || success"
                        placeholder="Ваш телефон"
                        :class="{
                          error: errors.includes('phone'),
                        }"
                        @input="changePhone"
                      />
                    </div>
                    <div
                      class="col-auto col-md-6 px-1 px-md-3"
                      :class="{ 'order-success': success }"
                    >
                      <button
                        @click="order()"
                        :disabled="orderDiscountPrice <= 0"
                        v-if="!success"
                      >
                        {{ ordering ? "Обработка..." : "Заказать" }}
                      </button>
                      <div class="success-text" v-if="success">
                        Заказ
                        <span>#{{ orderId }}</span>
                        оформлен!
                      </div>
                    </div>
                  </div>
                  <!--                        <span class="help d-md-none mt-3 text-center">
                                                          Скидка действует при заказе {{ dateNow }}!
                                                      </span>-->
                </div>
              </div>
              <div class="order-help">
                <div class="row mx-0">
                  <div class="col-auto px-0">
                    <img
                      v-lazy="{
                        src: require('../assets/alert.svg'),
                      }"
                      alt=""
                    />
                  </div>
                  <div class="col px-0" v-if="!success">
                    Чтобы оформить заказ, введите Ваш телефон. Мы перезвоним или
                    ответим в WhatsApp, Viber или Telegram для оформления
                    доставки на удобный пункт выдачи в Вашем городе.
                  </div>
                  <div class="col px-0" v-if="success">
                    Ожидайте звонка в течение 30 мин. Мы работаем с 9 до 21.00
                    по Москве
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!mobile">
            <div class="desktop-delivery-point" v-if="deliveryPoint">
              <div class="row mx-0 align-items-center">
                <div class="col-auto ps-0">
                  <img
                    v-lazy="{
                      src: require('../assets/car.svg'),
                    }"
                    alt=""
                    style="max-height: 25px"
                  />
                </div>
                <div class="col pe-0">
                  На пункт выдачи:
                  <span v-if="deliveryPoint.terminal">
                    {{ deliveryPoint.terminal.city_abbr }}
                    {{ deliveryPoint.terminal.city_name }},
                    {{ deliveryPoint.terminal.street_abbr }}
                    {{ deliveryPoint.terminal.street }}
                    {{ deliveryPoint.terminal.house_no }}
                  </span>
                  <span v-if="deliveryPoint.office">{{
                    deliveryPoint.office.address.fullAddress
                  }}</span>
                </div>
              </div>
            </div>
            <div class="order-mobile-box">
              <div class="order-list">
                <!-- eslint-disable -->
                <div v-for="item in sortedItems"
                     :key="item.id">
                    <div v-if="item.display" class="row order-list-item align-items-center"
                         :class="{'ml-check': item.subitem, 'mt-base': !item.subitem}"
                    >
                        <!-- eslint-enable -->
                    <div class="col-auto" @click="toggleItem(item)">
                      <dot-icon
                        v-if="item.is_radio"
                        class="order-check radio"
                        :value="item.active"
                        :class="{
                          checked: item.active,
                        }"
                      />
                      <check-icon
                        v-if="!item.is_radio"
                        class="order-check checkbox"
                        :class="{
                          checked: item.active,
                        }"
                      />
                    </div>
                    <div class="col-auto px-2 d-flex align-items-center">
                      <a
                        href="javascript:void(0)"
                        class="order-list-title"
                        v-if="item.subitem"
                        @click.prevent.stop="showItem(item)"
                        v-html="parseName(item)"
                      >
                      </a>
                      <span
                        class="order-list-title up"
                        v-if="!item.subitem"
                        @click="toggleItem(item)"
                        v-html="parseName(item)"
                      >
                      </span>

                      <switch-select
                        class="ps-2 pt-0"
                        v-if="item.variants"
                        :variants="item.variants"
                        :item="item"
                        :disabled="!item.active"
                        @click="addItem(item)"
                      />
                    </div>
                    <div class="col text-end" @click="toggleItem(item)">
                      <div
                        v-if="markAsGift.includes(item.id)"
                        class="order-list-free justify-content-end"
                      >
                        <img src="../assets/gift.svg" alt="" />
                        {{ freeText }}
                      </div>
                      <div
                        v-else
                        class="order-list-price fw-bold"
                        :class="{
                          // 'fw-bold': !item.subitem,
                        }"
                      >
                        <!--                        <span v-if="item.subitem">+</span>-->
                        <span v-if="getVariant(item.id).price > 0"
                          >{{ parsePrice(getVariant(item.id).price) }} ₽</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="order-promo dashed" v-if="promo.code && promoActive">
                <div class="promo-title">
                  Активирован промокод
                  <span class="code">{{ promo.code }}</span>
                </div>
                <!--                            <div>
                                                        <ul>
                                                            <li
                                                                v-for="gift in promo.gift_items"
                                                                :key="'gift_' + gift.id"
                                                            >
                                                                <span class="item-title">{{
                                                                    gift.name
                                                                }}</span>
                                                                в подарок
                                                            </li>
                                                        </ul>
                                                    </div>-->
              </div>
              <div class="order-price">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <span
                      class="old-value"
                      v-if="orderFullPrice != orderDiscountPrice"
                      >{{ parsePrice(orderFullPrice) }} ₽</span
                    >
                    <span class="value" v-if="orderDiscountPrice > 0"
                      >{{ parsePrice(orderDiscountPrice) }} ₽</span
                    >
                    <span v-if="orderDiscountPrice <= 0">
                      Для оформления заказа выберите
                      <a
                        href="javascript:void(0)"
                        @click="toggleItemId(columnId)"
                        >колонну</a
                      >
                      или
                      <a href="javascript:void(0)" @click="toggleItemId(tankId)"
                        >куб</a
                      >.
                    </span>
                    <!--                                <span class="value"
                                                                ><span class="value" id="orderPrice"></span>
                                                                ₽</span
                                                            >-->
                  </div>
                  <div
                    class="col d-none d-md-block"
                    v-if="orderFullPrice != orderDiscountPrice"
                  >
                    <span class="help">
                      {{ dateNow }} - Бесплатная доставка по РФ + скидка
                      <span>{{ orderFullPrice - orderDiscountPrice }} ₽</span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="order-form-action">
                <div class="row">
                  <div class="col-12 col-md-6 mb-1 mb-md-0">
                    <input
                      type="text"
                      v-maska="mask"
                      v-model="form.phone"
                      :disabled="ordering || success"
                      placeholder="Введите телефон"
                      :class="{
                        error: errors.includes('phone'),
                      }"
                      @input="changePhone"
                    />
                  </div>
                  <div class="col-12 col-md-6 mt-2 mt-md-0">
                    <button
                      @click="order()"
                      :disabled="orderDiscountPrice <= 0"
                      v-if="!success"
                    >
                      {{ ordering ? "Обработка..." : "Оформить заказ" }}
                    </button>
                    <div class="success-text" v-if="success">
                      Заказ
                      <span>#{{ orderId }}</span>
                      оформлен!
                    </div>
                  </div>
                </div>
                <!--                        <span class="help d-md-none mt-3 text-center">
                                                    Скидка действует при заказе {{ dateNow }}!
                                                </span>-->
              </div>
              <div class="order-help">
                <div class="row mx-0">
                  <div class="col-auto px-0">
                    <img
                      v-lazy="{
                        src: require('../assets/alert.svg'),
                      }"
                      alt=""
                    />
                  </div>
                  <div class="col px-0" v-if="!success">
                    Чтобы оформить заказ, введите Ваш телефон. Мы перезвоним или
                    ответим в WhatsApp, Viber или Telegram для оформления
                    доставки на удобный пункт выдачи в Вашем городе.
                  </div>
                  <div class="col px-0" v-if="success">
                    Ожидайте звонка в течение 30 мин. Мы работаем с 9 до 21.00
                    по Москве
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-auto" v-if="!mobile">
          <div class="order-image">
            <!--                        <img
                                        v-lazy="{
                                            src: require('../assets/order-paceholder.png'),
                                        }"
                                        alt=""
                                    />-->
            <canvas-editor
              ref="canvasDesktop"
              :cart="cart"
              :items="items"
              :base-id="baseId"
            />
          </div>
          <!--                    <div
                                  class="view3d"
                                  v-if="use3d"
                                  @mouseover="mouseover3d()"
                                  @mouseleave="mouseleave3d()"
                              >
                                  <model-viewer
                                      bounds="tight"
                                      enable-pan
                                      :src="BASE_URL + '/img/apparatDraco-processed.glb'"
                                      ar
                                      ar-modes="scene-viewer webxr quick-look"
                                      camera-controls
                                      :environment-image="
                                          BASE_URL + '/img/aircraft_workshop_01_1k.hdr'
                                      "
                                      :poster="require('../assets/order-paceholder.png')"
                                      shadow-intensity="1"
                                      shadow-softness="1"
                                      exposure="1.19"
                                      @wheel.prevent
                                      @touchmove.prevent
                                      @scroll.prevent
                                  >
                                      <div class="progress-bar hide" slot="progress-bar">
                                          <div class="update-bar"></div>
                                      </div>
                                      <button slot="ar-button" id="ar-button">
                                          Посмотреть у себя...
                                      </button>
                                      <div id="ar-prompt">
                                          <img
                                              v-lazy="{
                                                  src: 'https://modelviewer.dev/shared-assets/icons/hand.png',
                                              }"
                                          />
                                      </div>
                                  </model-viewer>
                              </div>-->
        </div>
      </div>
    </div>
  </div>
  <!--    <app-footer v-if="!mobile" />-->
</template>

<script>
import CanvasEditor from "@/pages/Main/ui/CanvasEditor";
import {
  TOGGLE_ITEM,
  EDIT_MODAL,
  CREATE_ORDER,
  SET_VARIANT,
} from "@/store/modules/order";
import { SET_USER_PHONE } from "@/store/modules/user";
// import CheckIcon from "@/pages/Main/components/svg/CheckIcon";
import DotIcon from "@/pages/Main/components/svg/DotIcon";
import CheckIcon from "@/pages/Main/components/svg/CheckIcon";
import SwitchSelect from "@/pages/Main/components/SwitchSelect";
// import Odometer from "odometer";
// require("odometer/themes/odometer-theme-default.css");
// import AppFooter from "@/components/AppFooter";
const BASE_URL =
  process.env.NODE_ENV === "production" ? process.env.BASE_URL : "";

export default {
  name: "OrderForm",
  components: { SwitchSelect, CheckIcon, DotIcon, CanvasEditor },
  computed: {
    cart() {
      return this.$store.getters.cart;
    },
    deliveryPoint() {
      return this.$store.getters.deliveryPoint;
    },
    kits() {
      return this.$store.getters.kits;
    },
    cartIds() {
      let cartForSent = this.$store.getters.cart.map((v) => {
        let res = null;
        let item = this.$store.getters.items.find((find) => find.id == v.id);
        if (item.variant_id) {
          res = item.variants.find((variant) => variant.id == item.variant_id);
        } else {
          res = item;
        }
        return { ...v, id: res.id };
      });

      return cartForSent.map((v) => {
        return v.id;
      });
    },
    items() {
      return this.$store.getters.items;
    },
    sortedItems() {
      // let groups = {
      //   0: {
      //     id: 0,
      //     items: [],
      //   },
      // };
      let groups = [];
      this.$store.getters.items.map((v) => {
        if (!v.subitem) {
          groups[v.id] = {
            ...v,
            items: [],
          };
        }
      });

      let prepareItems = [];

      this.$store.getters.items.forEach((v) => {
        // if (!v.subitem) groups[0].items.push(v);
        if (v.subitem) groups[v.subitem].items.push(v);
      });
      groups = groups
        .filter(Boolean)
        .sort((a, b) =>
          a.sort_id && b.sort_id
            ? parseFloat(a.sort_id) - parseFloat(b.sort_id)
            : 0
        );
      // Object.keys(groups).forEach((v) => {
      //   if (v != 0) {
      //     prepareItems.push(this.getItem(v));
      //     groups[v].items.forEach((i) => {
      //       prepareItems.push(this.getItem(i.id));
      //     });
      //   }
      // });
      groups.forEach((v) => {
        prepareItems.push(this.getItem(v.id));
        v.items
          .sort((a, b) =>
            a.sort_id && b.sort_id
              ? parseFloat(a.sort_id) - parseFloat(b.sort_id)
              : 0
          )
          .forEach((i) => {
            prepareItems.push(this.getItem(i.id));
          });
      });
      return prepareItems;
    },
    orderId() {
      return this.$store.getters.orderId;
    },
    promo() {
      return this.$store.getters.promo;
    },
    // orderPrice() {
    //     return this.$store.getters.orderPrice;
    // },
    orderFullPrice() {
      let ebuCart = this.$store.getters.cart;
      // if (
      //   ebuCart.map((v) => v.id).includes(2273) &&
      //   ebuCart.map((v) => v.id).includes(2149)
      // ) {
      //   ebuCart = ebuCart.filter((v) => v.id !== 2273 && v.id !== 2149);
      //   ebuCart.push({
      //     id: 2279,
      //     count: 1,
      //   });
      // }
      return this.$store.getters.items
        .filter((v) => ebuCart.map((s) => s.id).includes(v.id))
        .reduce((pv, v) => {
          let i = null;
          if (v.variant_id) {
            i = v.variants.find((variant) => variant.id == v.variant_id);
          } else {
            i = v;
          }
          return pv + i.price * v.count;
        }, 0);
    },
    realCart() {
      let usedKit = false;
      let cartForSent = this.$store.getters.cart;
      this.kits.forEach((value) => {
        if (!usedKit) {
          let activeKit = value.items.every((r) => this.cartIds.includes(r));
          if (activeKit) {
            usedKit = true;

            cartForSent = cartForSent.filter(
              (v) => !value.items.includes(v.id)
            );

            cartForSent.push({
              id: value.id,
              count: 1,
            });
          }
        }
      });
      return cartForSent;
    },
    promoActive() {
      let res = [];
      if (Object.keys(this.promo.required_items).length > 0) {
        let usedKit = false;
        let cartForSent = this.$store.getters.cart;
        this.kits.forEach((value) => {
          if (!usedKit) {
            let activeKit = value.items.every((r) => this.cartIds.includes(r));
            if (activeKit) {
              usedKit = true;

              cartForSent = cartForSent.filter(
                (v) => !value.items.includes(v.id)
              );

              cartForSent.push({
                id: value.id,
                count: 1,
              });
            }
          }
        });
        if (this.promo.any) {
          res = this.promo.required_items.some((r) =>
            cartForSent.map((v) => v.id).includes(r)
          );
        } else {
          res = this.promo.required_items.every((r) =>
            cartForSent.map((v) => v.id).includes(r)
          );
        }
      } else {
        res = false;
      }
      return res;
    },
    markAsGift() {
      let self = this;
      let gifts = [];
      self.$store.getters.gifts.forEach((value) => {
        let requiredItems = value.conditions
          .filter((f) => f.type === "item")
          .map((i) => {
            return i.id;
          });
        let activeGift = false;
        if (value.any) {
          // activeGift = requiredItems.some((r) => self.cartIds.includes(r));
          activeGift = requiredItems.some((r) =>
            self.realCart.map((v) => v.id).includes(r)
          );
        } else {
          // activeGift = requiredItems.every((r) => self.cartIds.includes(r));
          activeGift = requiredItems.every((r) =>
            self.realCart.map((v) => v.id).includes(r)
          );
        }
        if (activeGift) {
          gifts.push(value.id);
        }
      });
      return gifts;
    },
    orderDiscountPrice() {
      let self = this;
      let usedKit = false;

      let ebuCart = this.$store.getters.cart;
      // if (
      //   ebuCart.map((v) => v.id).includes(2273) &&
      //   ebuCart.map((v) => v.id).includes(2149)
      // ) {
      //   ebuCart = ebuCart.filter((v) => v.id !== 2273 && v.id !== 2149);
      //   ebuCart.push({
      //     id: 2279,
      //     count: 1,
      //   });
      // }

      return self.$store.getters.items
        .filter((v) => ebuCart.map((s) => s.id).includes(v.id))
        .reduce((pv, v) => {
          let item = null;
          if (v.variant_id) {
            item = v.variants.find((variant) => variant.id == v.variant_id);
          } else {
            item = v;
          }

          let skipCalcIds = [];
          let discount = 0;
          // console.log(state.gifts.every((r) => state.cartIds.includes(r)));
          self.$store.getters.gifts.forEach((value) => {
            if (value.id === v.id) {
              let requiredItems = value.conditions
                .filter((f) => f.type === "item")
                .map((i) => {
                  return i.id;
                });
              let activeGift = false;
              if (value.any) {
                activeGift = requiredItems.some((r) =>
                  self.realCart.map((v) => v.id).includes(r)
                );
              } else {
                activeGift = requiredItems.every((r) =>
                  self.realCart.map((v) => v.id).includes(r)
                );
              }
              if (activeGift) {
                skipCalcIds.push(v.id);
              }
            }
          });
          self.$store.getters.kits.forEach((value) => {
            if (!usedKit) {
              let activeKit = value.items.every((r) =>
                self.cartIds.includes(r)
              );
              if (activeKit) {
                discount = value.discount;
                usedKit = true;
                return false;
              }
            }
          });
          return skipCalcIds.includes(v.id)
            ? pv
            : pv + item.price * v.count - discount;
        }, 0);
    },
    dateNow() {
      return this.$store.getters.date;
    },
    // cartComputed() {
    //     return [...this.cart, ...this.items]
    //         .filter((v) => v.active)
    //         .map((v) => {
    //             return { id: v.id, count: v.count };
    //         });
    // },
    baseItem() {
      return this.items.find((v) => parseInt(v.id) === parseInt(this.baseId));
    },
    columnItem() {
      return this.items.find((v) => parseInt(v.id) === parseInt(this.columnId));
    },
    tankItem() {
      return this.items.find((v) => parseInt(v.id) === parseInt(this.tankId));
    },
    upItem() {
      return this.items.find((v) => parseInt(v.id) === parseInt(this.upperId));
    },
    // discount() {
    //     let discount = 0;
    //
    //     let activeItemIds = [];
    //     this.cart.forEach((v) => {
    //         activeItemIds.push(v.id);
    //     });
    //
    //     if (
    //         activeItemIds.includes(this.columnId) &&
    //         activeItemIds.includes(2273) &&
    //         activeItemIds.includes(this.tankId)
    //     ) {
    //         discount = 2020;
    //     } else if (
    //         activeItemIds.includes(this.columnId) &&
    //         activeItemIds.includes(this.tankId)
    //     ) {
    //         discount = 1990;
    //     }
    //
    //     return discount;
    // },
  },
  watch: {
    "form.phone"(value) {
      if (value.length <= 18) {
        this.mask = "+# (###) ###-##-###";
      } else {
        this.mask = "+## (###) ###-##-##";
      }
    },
  },
  data() {
    return {
      BASE_URL,
      mobile: false,
      // use3d: false,
      // cart: [
      // ],
      // items: [
      // ],f
      modal: false,
      baseId: 2558,
      form: {
        phone: "",
      },
      success: false,
      ordering: false,
      errors: [],
      // orderPriceElement: null,
      freeText: "Подарок",
      mask: "+# (###) ###-##-###",

      tankId: 2550,
      columnId: 2554,
      upperId: 2558,
    };
  },
  methods: {
    toggleItem(item) {
      // item.active = !item.active;
      // if (item.layers) {
      //     item.layers.forEach((v) => {
      //         if (item.active) this.showElement(v);
      //         else this.hideElement(v);
      //     });
      // }
      if (!this.orderId) this.$store.dispatch(TOGGLE_ITEM, item);
    },
    addItem(item) {
      // item.active = !item.active;
      // if (item.layers) {
      //     item.layers.forEach((v) => {
      //         if (item.active) this.showElement(v);
      //         else this.hideElement(v);
      //     });
      // }
      if (!this.orderId && !item.active)
        this.$store.dispatch(TOGGLE_ITEM, item);
    },
    toggleItemId(id) {
      let item = this.items.find((v) => v.id === id);
      this.toggleItem(item);
    },
    toggleBaseItem(item) {
      if (item.count == 0) {
        this.incrementItem(item);
      } else {
        this.decrementItem(item);
      }
    },
    incrementItem(item) {
      if (!this.orderId) item.count += 1;
      if (this.mobile) this.$refs.canvasMobile.updateCanvas();
      if (!this.mobile) this.$refs.canvasDesktop.updateCanvas();
      if (item.count > 0) item.active = true;
    },
    decrementItem(item) {
      if (item.count <= 0) return;
      if (!this.orderId) item.count -= 1;
      if (this.mobile) this.$refs.canvasMobile.updateCanvas();
      if (!this.mobile) this.$refs.canvasDesktop.updateCanvas();
      if (item.count <= 0) item.active = false;
    },
    // hideElement(name) {
    //     if (this.use3d) {
    //         const modelViewer = document.querySelector("model-viewer");
    //
    //         let material = modelViewer.materialByName(name);
    //
    //         if (material != null) {
    //             material.setAlphaMode("MASK");
    //             material.pbrMetallicRoughness.setBaseColorFactor([
    //                 material.pbrMetallicRoughness.baseColorFactor[0],
    //                 material.pbrMetallicRoughness.baseColorFactor[1],
    //                 material.pbrMetallicRoughness.baseColorFactor[2],
    //                 0,
    //             ]);
    //         }
    //     }
    // },
    // showElement(name) {
    //     if (this.use3d) {
    //         const modelViewer = document.querySelector("model-viewer");
    //
    //         let material = modelViewer.materialByName(name);
    //
    //         if (material != null) {
    //             material.setAlphaMode("OPAQUE");
    //             material.pbrMetallicRoughness.setBaseColorFactor([
    //                 material.pbrMetallicRoughness.baseColorFactor[0],
    //                 material.pbrMetallicRoughness.baseColorFactor[1],
    //                 material.pbrMetallicRoughness.baseColorFactor[2],
    //                 1,
    //             ]);
    //         }
    //     }
    // },
    // mouseover3d() {
    //     window.fullpage_api.setMouseWheelScrolling(false);
    //     window.fullpage_api.setAllowScrolling(false);
    // },
    // mouseleave3d() {
    //     window.fullpage_api.setMouseWheelScrolling(true);
    //     window.fullpage_api.setAllowScrolling(true);
    // },
    showItem(item) {
      // if (item.subitem)
      this.$store.commit(EDIT_MODAL, item);
    },
    // closeItem() {
    //     // this.modal = false;
    // },
    parsePrice: function (str, symbol = "’") {
      return parseFloat(str)
        .toString()
        .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1" + symbol);
    },
    order() {
      if (!this.ordering && !this.success && !this.orderId) {
        this.ordering = true;
        this.errors = [];
        this.$store
          .dispatch(CREATE_ORDER, {
            promo_active: this.promoActive,
            clientId: this.$metrika.getClientID(),
          })
          .then(() => {
            this.success = true;
            this.ordering = false;
            if (this.cartIds.includes(2554) && !this.cartIds.includes(2550)) {
              this.$metrika.reachGoal("column");
            } else if (
              !this.cartIds.includes(2554) &&
              this.cartIds.includes(2550)
            ) {
              this.$metrika.reachGoal("cube");
            } else {
              this.$metrika.reachGoal("order");
            }
          })
          .catch((errors) => {
            this.errors = errors;
            this.ordering = false;
          });
      }
    },
    changePhone() {
      if (this.errors.includes("phone"))
        this.errors = this.errors.map((v) => v != "phone");
      this.$store.commit(SET_USER_PHONE, this.form.phone);
    },
    arrayGiftCheck(giftArray) {
      return giftArray.every((r) => this.cartIds.includes(r));
    },
    parseName(item) {
      let response;
      let value = "full";
      if (window.innerWidth < 1200) value = "short";
      if (item.hidePlus) {
        response = "<span class='text'>" + item.name[value] + "</span>";
      } else {
        response =
          "<span class='plus'>+</span><span class='text'>" +
          item.name[value] +
          "</span>";
      }
      return response;
    },
    getItem(id) {
      return this.items.find((v) => parseInt(v.id) === parseInt(id));
    },
    getVariant(itemId) {
      let item = this.getItem(itemId);
      let res;
      if (item.variant_id) {
        res = item.variants.find((variant) => variant.id == item.variant_id);
      } else {
        res = item;
      }
      return res;
    },
    // setVariant() {
    //   // console.log(param);
    //   // this.$store.dispatch(SET_VARIANT, param);
    //   // param.item.variant_id = param.variant.id;
    //   if (this.mobile) this.$refs.canvasMobile.updateCanvas();
    //   if (!this.mobile) this.$refs.canvasDesktop.updateCanvas();
    // },
  },
  mounted() {
    let self = this;
    // this.$store.dispatch(
    //     ADD_TO_CART,
    //     this.items.find((v) => parseInt(v.id) === parseInt(this.baseId))
    // );

    if (window.innerWidth <= 991) this.mobile = true;
    this.$store.subscribeAction((action) => {
      if (action.type === SET_VARIANT) {
        self.$nextTick(() => {
          // console.log(self.mobile);
          if (self.mobile && self.$refs.canvasMobile)
            self.$refs.canvasMobile.updateCanvas();
          if (!self.mobile && self.$refs.canvasDesktop)
            self.$refs.canvasDesktop.updateCanvas();
        });
      }
    });
    /*let self = this;
            if (this.use3d) {
                const onProgress = (event) => {
                    const progressBar = event.target.querySelector(".progress-bar");
                    const updatingBar = event.target.querySelector(".update-bar");
                    updatingBar.style.width = `${
                        event.detail.totalProgress * 100
                    }%`;
                    if (event.detail.totalProgress === 1) {
                        progressBar.classList.add("hide");
                    } else {
                        progressBar.classList.remove("hide");
                        if (
                            event.detail.totalProgress === 0 &&
                            event.target.querySelector(".center-pre-prompt")
                        ) {
                            event.target
                                .querySelector(".center-pre-prompt")
                                .classList.add("hide");
                        }
                    }
                };
                const modelViewer = document.querySelector("model-viewer");
                modelViewer.addEventListener("load", () => {
                    [...self.cart, ...self.items].forEach((item) => {
                        if (item.layers) {
                            item.layers.forEach((v) => {
                                if (item.active) this.showElement(v);
                                else this.hideElement(v);
                            });
                        }
                    });
                });
                modelViewer.addEventListener("progress", onProgress);
            }*/
    // this.showItem(this.items[0]);

    // this.orderPriceElement = new Odometer({
    //     el: document.getElementById("orderPrice"),
    //     value: 0,
    //
    //     // Any option (other than auto and selector) can be passed in here
    //     format: "(’ddd)",
    //     theme: "",
    //     duration: 500,
    // });
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: 700;
  font-size: 42px;
  line-height: 52px;
  //margin-top: 50px;
  margin-bottom: 24px;

  @media (max-width: 767px) {
    text-align: center;
    font-size: 28px;
    line-height: 34px;
  }
}

.title-help {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #909090;
  opacity: 0.99;
  max-width: 390px;
  margin-bottom: 32px;
  @media (max-width: 767px) {
    text-align: center;
    margin-bottom: 24px;
  }
}

.order-form {
  //background: linear-gradient(
  //    125.93deg,
  //    rgba(41, 41, 43, 0.5) 8.52%,
  //    rgba(34, 34, 34, 0.5) 90.16%
  //);
  background-color: #232323;
  border-radius: 15px;
  //padding: 40px 30px;
  padding: 60px 60px;
  backdrop-filter: blur(5px);

  @media (min-width: 991px) and (max-width: 1200px) {
    background-color: transparent;
    padding: 0;
  }

  .up-help {
    font-size: 14px;
    position: absolute;
    font-style: italic;
    color: #a3a3a3;
  }

  .order-size {
    .order-size-title {
      font-size: 28px;

      @media (max-width: 767px) {
        font-size: 20px;
      }
    }

    .select-size {
      border: 2px solid #777777;
      box-sizing: border-box;
      border-radius: 7px;
      padding: 8px 12px;
      font-size: 24px;
      line-height: 28px;

      .size-value {
        padding: 0;
        width: 70px;
        text-align: center;
        font-size: 20px;
      }

      .add-text {
        font-size: 20px;
        color: #a5a5a5;
        padding-right: 0;
        width: 118px;
        text-align: center;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          color: #d0d0d0;
        }
      }

      button {
        background: #777777;
        border-radius: 100px;
        border: none;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        padding-bottom: 3px;
        transition: 0.1s;

        &:hover {
          background: #0086fc;
        }
      }
    }

    .image-size {
      width: 69px;
      height: 69px;
      @media (max-width: 767px) {
        width: 80px;
        height: 80px;
      }
    }

    @media (max-width: 767px) {
      margin-bottom: 20px;
      display: none;
    }
  }

  .image-container {
    //margin-left: -90px;
    //margin-right: -30px;
    height: 400px;

    @media (max-width: 991px) and (min-width: 768px) {
      height: 660px;
      margin-left: -260px;
      margin-right: -20px;
      margin-bottom: -120px;
    }

    @media (max-width: 767px) {
      height: auto;
      //margin-left: -230px;
      //margin-right: -10px;
      //margin-bottom: -70px;
      //margin-top: 30px;
    }
  }

  .order-list {
    margin-top: 30px;
    z-index: 1;
    @media (max-width: 991px) {
      margin-bottom: 30px;
      padding-left: 16px;
    }
    @media (max-width: 767px) {
      margin-top: -55px;
      margin-bottom: 5px;
      padding-right: 19px;
      padding-left: 16px;
      min-width: 360px;
    }

    .order-list-item {
      //margin-bottom: 16px;
      margin-top: 10px;
      padding: 0px 0;
      border-radius: 10px;
      cursor: pointer;
      /* purgecss start ignore */
      -ms-user-select: none;
      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      /* purgecss end ignore */

      @media (min-width: 991px) {
        &:hover {
          //background: #ffffff24;
        }
      }

      @media (max-width: 991px) {
        padding: 0;
        border: none;
        margin-bottom: 6px;
      }

      @media (max-width: 767px) {
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 5px;
      }

      &.fs-min {
        .order-list-title {
          font-weight: 400;
          font-size: 16px;
        }
      }

      &.mt-item {
        margin-top: 32px;
        margin-right: 12px;
      }

      &.ml-check {
        margin-left: 35px !important;
      }

      &.mt-base {
        margin-top: 32px !important;
      }
    }

    .order-list-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #0086fc;

      &.up {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #ffffff;
        border-bottom: none;
        text-decoration: none;

        &:hover {
          color: #ffffff;
        }

        @media (max-width: 767px) {
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
        }
      }

      &:hover {
        text-decoration: none;
        color: #0086fc;
      }

      @media (max-width: 991px) {
        line-height: 1;
      }
      @media (max-width: 767px) {
        border-bottom: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        display: inline-block;
        //text-decoration: underline;

        &.white-title {
          font-size: 18px;
          line-height: 22px;
          color: #fff;
          text-decoration: none;
        }
      }
    }

    .order-list-free {
      background-image: linear-gradient(to left, #ffa353, #d95e00);
      color: transparent;

      /* purgecss start ignore */
      background-clip: text;
      -webkit-background-clip: text;
      text-shadow: 0 0 transparent;
      /* purgecss end ignore */

      font-weight: 900;
      font-size: 20px;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 30px;

      @media (max-width: 991px) {
        font-size: 16px;
        justify-content: start;
        line-height: 27px;
      }

      img {
        width: 18px;
        margin-right: 5px;
      }
    }
  }

  .order-list-price {
    color: #06db71;
    //background-image: linear-gradient(to left, #ffa353, #d95e00);
    //color: transparent;
    //background-clip: text;
    font-weight: 400;
    font-size: 20px;
    @media (max-width: 991px) {
      font-size: 20px;
    }
    @media (max-width: 767px) {
      font-size: 16px;
    }

    span {
      margin-right: 3px;
    }
  }

  .order-check {
    width: 22px;
    height: 22px;

    background: #2f2f2f;
    color: transparent;
    fill: #2f2f2f;

    &.radio {
      border-radius: 100px;
      border: 1px solid #488eff;

      &.checked {
        border-radius: 100%;
        background: transparent;
        color: #488eff;
        border: 0px solid #488eff;
      }
    }

    &.checkbox {
      background: #2f2f2f;
      padding: 4px;
      border-radius: 1px;
      transition: 0.1s;
      border: 1px solid #488eff;

      &.checked {
        color: #488eff;
        border: 1px solid #488eff;
      }
    }

    @media (max-width: 991px) {
      margin-right: 12px;
    }
    @media (max-width: 767px) {
      width: 25px;
      height: 25px;
      padding: 5px !important;
    }
  }

  .order-price {
    margin-top: 32px;

    .all-title {
      font-weight: 700;
      font-size: 16px;
      text-transform: uppercase;
      line-height: 32px;

      @media (max-width: 767px) {
        line-height: 20px;
        text-align: right;
        text-transform: none;
      }
    }

    .old-value {
      font-weight: 500;
      font-size: 24px;
      //text-decoration-line: line-through;
      color: #8f8f8f;
      margin-right: 22px;
      line-height: 1;
      position: relative;
      display: inline-block;

      &:before {
        position: absolute;
        content: "";
        top: 50%;
        left: -5px;
        width: calc(100% + 10px);
        height: 1px;
        background: #ed492e;
        transform: rotate(345deg);
      }

      @media (max-width: 991px) {
        margin-right: 0;
      }

      @media (max-width: 767px) {
        text-align: center;
        font-size: 16px;
        line-height: 24px;
        margin-top: -8px;
      }
    }

    .value {
      font-weight: 700;
      font-size: 52px;
      color: #06db71;
      line-height: 52px;

      @media (max-width: 767px) {
        font-size: 22px;
        line-height: 26px;
        text-align: center;
      }
    }

    @media (max-width: 767px) {
      margin-top: 5px;
      //max-width: 350px;
      border-top: 1px solid rgba(196, 196, 196, 0.25);
      border-bottom: 1px solid rgba(196, 196, 196, 0.25);
      padding-bottom: 16px;
      padding-top: 16px;
      margin-bottom: 16px;
      .row.mt-3.mb-1 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }
    }

    a {
      color: #008fff;
    }
  }

  .help {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #fff;
    display: block;

    @media (max-width: 767px) {
      background-image: linear-gradient(270deg, #ffa353 32.29%, #d95e00 100%);
      color: transparent;

      /* purgecss start ignore */
      background-clip: text;
      -webkit-background-clip: text;
      text-shadow: 0 0 transparent;
      /* purgecss end ignore */
    }
  }

  .order-form-action {
    input {
      background: #f1f1f1;
      border-radius: 10px;
      padding: 12px 20px 12px 20px;
      color: #505050;
      font-size: 18px;
      width: 100%;
      border: 1px solid transparent;
      transition: 0.3s;

      &::placeholder {
        font-size: 18px;
        color: #505050;
      }

      &.error {
        border-color: #ff4f4f;
        background: #ffe0e0;
      }

      @media (max-width: 767px) {
        font-size: 14px;
        padding: 9px 10px 8px 15px;
        min-width: 120px;
      }
    }

    button {
      padding: 12px 0px 11px;
      background: #2a93f4;
      border-radius: 10px;
      font-weight: 700;
      font-size: 20px;
      border: none;
      width: 100%;
      color: #fff;

      &[disabled] {
        background: #444444;
      }

      @media (max-width: 767px) {
        font-size: 18px;
        padding: 7px 10px 6px;
      }
    }

    margin-top: 24px;

    @media (max-width: 767px) {
      margin-top: 7px;
      padding-left: 10px;
      padding-right: 10px;
    }

    .order-success {
      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }

  .order-help {
    margin-top: 20px;
    color: #909090;
    font-size: 12px;
    line-height: 16px;

    img {
      margin-right: 13px;
    }

    @media (max-width: 767px) {
      max-width: 350px;
    }
  }

  .order-image {
    background: radial-gradient(50% 50% at 50% 50%, #555555 0%, #393939 100%);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    //padding: 33px 65px;
    height: 100%;
    min-height: 650px;
    flex-direction: column;
    min-width: 333px;
    //background-image: url("../assets/lol.png");

    @media (max-width: 991px) {
      min-height: auto;
      height: auto;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      flex-direction: row;
      padding-top: 30px;
      padding-bottom: 40px;
      background: radial-gradient(
        50% 50% at 50% 50%,
        #55555585 0%,
        #39393973 100%
      );
    }

    @media (max-width: 767px) {
      max-width: 360px;
      max-height: auto;
      padding: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      background: transparent;
    }

    .order-left-box {
      //width: 190px;
      z-index: 2;
      @media (min-width: 769px) and (max-width: 991px) {
        min-width: 260px;
      }
    }
  }

  @media (max-width: 991px) and (min-width: 768px) {
    margin-bottom: 20px;
  }

  @media (max-width: 767px) {
    background-color: transparent;

    background-image: radial-gradient(
      circle,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(255, 255, 255, 0.1) 10%,
      rgba(0, 0, 0, 0) 30%,
      rgba(0, 0, 0, 0) 100%
    );
    background-position: 60px -110px;
    border-radius: 0;
    padding: 0;
    backdrop-filter: blur(5px);
  }
}

.view3d {
  //background: radial-gradient(50% 50% at 50% 50%, #555555 0%, #393939 100%);
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  //padding: 33px 65px;
  height: 100%;
  min-height: 650px;

  model-viewer {
    width: 100%;
    height: 100%;
    background: radial-gradient(50% 50% at 50% 50%, #555555 0%, #393939 100%);
    display: block;
    border-radius: 15px;
  }

  .progress-bar {
    display: block;
    width: 33%;
    height: 10%;
    max-height: 2%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    border-radius: 25px;
    box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.5),
      0px 0px 5px 1px rgba(0, 0, 0, 0.6);
    border: 1px solid rgba(255, 255, 255, 0.9);
    background-color: rgba(0, 0, 0, 0.5);
  }

  .progress-bar.hide {
    visibility: hidden;
    transition: visibility 0.3s;
  }

  .update-bar {
    background-color: rgba(255, 255, 255, 0.9);
    width: 0%;
    height: 100%;
    border-radius: 25px;
    float: left;
    transition: width 0.3s;
  }

  #ar-button {
    background-image: url(../assets/apparat/ar_icon.png);
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: 12px 50%;
    background-color: #fff;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    bottom: 16px;
    padding: 0px 16px 0px 40px;
    font-family: Roboto Regular, Helvetica Neue, sans-serif;
    font-size: 14px;
    color: #4285f4;
    height: 36px;
    line-height: 36px;
    border-radius: 18px;
    border: 1px solid #dadce0;
  }

  #ar-button:active {
    background-color: #e8eaed;
  }

  #ar-button:focus {
    outline: none;
  }

  #ar-button:focus-visible {
    outline: 1px solid #4285f4;
  }

  @keyframes circle {
    from {
      transform: translateX(-50%) rotate(0deg) translateX(50px) rotate(0deg);
    }
    to {
      transform: translateX(-50%) rotate(360deg) translateX(50px)
        rotate(-360deg);
    }
  }

  @keyframes elongate {
    from {
      transform: translateX(100px);
    }
    to {
      transform: translateX(-100px);
    }
  }

  model-viewer > #ar-prompt {
    position: absolute;
    left: 50%;
    bottom: 60px;
    animation: elongate 2s infinite ease-in-out alternate;
    display: none;
  }

  model-viewer[ar-status="session-started"] > #ar-prompt {
    display: block;
  }

  model-viewer > #ar-prompt > img {
    animation: circle 4s linear infinite;
  }
}

.order-box {
  padding-right: 48px;
  @media (max-width: 767px) {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    .order-mobile-box {
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      padding-left: calc(var(--bs-gutter-x) * 0.5);
      max-width: 360px;
    }
  }
}

.success-text {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  font-weight: 600;
  font-size: 18px;

  span {
    color: #0086fc;
    padding: 0 5px;
  }
  @media (max-width: 767px) {
    text-align: center;
    margin-top: 10px;
  }
}

.order-promo {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23FFFFFF70' stroke-width='3' stroke-dasharray='4%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  background-color: #2f2f2f;
  border-radius: 12px;
  padding: 12px 17px 13px;
  margin-top: 52px;
  margin-bottom: -14px;

  .promo-title {
    color: #c4c4c4;

    .code {
      color: #0086fc;
      font-weight: 600;
    }
  }

  ul {
    list-style-type: "🎁";
    margin-top: 5px;
    margin-bottom: 0;
    padding-left: calc(1rem + 5px);

    li {
      padding-left: 5px;
    }
  }

  .item-title {
    color: #0086fc;
    font-weight: 600;
  }

  @media (max-width: 767px) {
    margin: 0 12px 37px;
  }
}

.mobile-select-core-items {
  padding-left: 16px;
  padding-right: 12px;
  font-size: 14px;
  font-weight: 700;
  .order-list-price {
    font-size: 16px;
    font-weight: 700;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    min-width: 400px;
  }
}

.sw-padding {
  padding-left: 30px;
}

.mobile-delivery-point {
  max-width: 360px;
  background: rgb(255 255 255 / 11%);
  border-radius: 10px;
  padding: 10px 15px;
  border: 1px solid #2a93f4;
  font-size: 12px;
}

.desktop-delivery-point {
  background: rgb(255 255 255 / 11%);
  border-radius: 10px;
  padding: 10px 15px;
  border: 2px solid #2a93f4;
}
</style>

<style lang="scss">
:root {
  --poster-color: transparent;
  --poster-padding: 33px 65px;
}

.order-list-title {
  text-decoration: none;

  .plus {
    margin-right: 5px;
    display: inline-block;
  }

  &:not(.up) {
    .text {
      border-bottom: 1px solid #0086fc;
    }
  }

  &:hover {
    &:not(.up) {
      color: #0086fc;
    }

    .text {
      border-color: #0086fc;
    }
  }
}
</style>
