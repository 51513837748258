<template>
  <canvas
    :style="{ 'margin-top': 0 - correctY + 10 + 'px' }"
    style="transition: margin 500ms; transition-timing-function: ease"
    id="canvas"
    width="338"
    height="700"
  ></canvas>
</template>

<script>
import { inject } from "vue";
export default {
  name: "CanvasEditor",
  props: {
    items: {
      default: null,
    },
    // cart: {
    //   default: null,
    // },
    baseId: {
      default: null,
    },
  },
  data() {
    return {
      maxBaseCount: 1,
      canvas: null,
      context: null,
      load: false,
      correctY: 0,
    };
  },
  watch: {
    cart: {
      handler() {
        this.updateCanvas();
      },
      deep: true,
    },
  },
  computed: {
    cart() {
      return this.$store.getters.cart;
    },
    baseIdCount() {
      return this.items.find((item) => item.id === this.baseId).count;
    },
  },
  methods: {
    updateCanvas() {
      // let self = this;
      let drawImagePool = [];
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

      let ebuCart = this.cart;

      // if (
      //   ebuCart.map((v) => v.id).includes(2273) &&
      //   ebuCart.map((v) => v.id).includes(2149)
      // ) {
      //   ebuCart = ebuCart.filter((v) => v.id !== 2273 && v.id !== 2149);
      //   ebuCart.push({
      //     id: 2279,
      //     count: 1,
      //   });
      // }

      ebuCart.forEach((cartValue) => {
        let item = this.items.find((item) => item.id === cartValue.id);
        let layers = null;
        if (item.variant_id) {
          layers = item.variants.find(
            (variant) => variant.id == item.variant_id
          ).layers;
        } else {
          layers = item.layers;
        }
        Object.keys(layers).forEach((index) => {
          if (
            index === "*" ||
            parseInt(index) ===
              parseInt(
                this.baseIdCount > this.maxBaseCount
                  ? this.maxBaseCount
                  : this.baseIdCount
              )
          ) {
            layers[index].forEach((layer) => {
              if (layer.image) {
                drawImagePool.push(layer);
              }
            });
          }
        });
      });
      let hidePool = [];
      drawImagePool
        .filter((v) => v.hide)
        .forEach((v) => {
          hidePool = hidePool.concat(v.hide);
        });
      drawImagePool
        .filter((v) => {
          // console.log(
          //   v.hideIfNotActive,
          //   drawImagePool.map((v) => v.name),
          //   v.hideIfNotActive.some((r) =>
          //     drawImagePool.map((v) => v.name).includes(r)
          //   )
          // );
          return (
            v.hideIfNotActive &&
            !v.hideIfNotActive.some((r) =>
              drawImagePool.map((v) => v.name).includes(r)
            )
          );
        })
        .forEach((v) => {
          // console.log(v);
          hidePool.push(v.name);
        });
      // console.log(
      //   drawImagePool,
      //   hidePool,
      //   drawImagePool.filter((i) => !hidePool.includes(i.name))
      // );
      // console.log(
      //   drawImagePool,
      //   drawImagePool.filter(
      //     (v) =>
      //       v.hideIfNotActive &&
      //       v.hideIfNotActive.some((r) =>
      //         this.cart.map((v) => v.id).includes(r)
      //       )
      //   )
      // );
      // drawImagePool
      //   .filter((v) =>
      //     v.hideIfNotActive && v.hideIfNotActive.some((r) => this.cart.map((v) => v.id).includes(r))
      //   )
      //   .forEach((v) => {
      //     hidePool = hidePool.concat(v.name);
      //   });
      drawImagePool
        .filter((i) => !hidePool.includes(i.name))
        .sort((a, b) => parseInt(a.layer) - parseInt(b.layer))
        .forEach((layer) => {
          this.context.drawImage(layer.image, 0, 0);
        });

      if (window.innerWidth < 768) {
        let canvasData = this.context.getImageData(
          0,
          0,
          this.canvas.width,
          this.canvas.height
        );
        // let hasAlphaPixels = false;

        // function drawPixel(index, x, y, r, g, b, a) {
        //   // let index = (x + y * this.canvas.width) * 4;
        //
        //   canvasData.data[index + 0] = r;
        //   canvasData.data[index + 1] = g;
        //   canvasData.data[index + 2] = b;
        //   canvasData.data[index + 3] = a;
        // }

        // let prevBodyHeight = document.body.offsetHeight;

        // let prevCorrect = this.correctY;

        for (let i = 0, n = canvasData.data.length; i < n; i += 4) {
          // this.context.fillRect(i, 1, 1, 1);

          if (canvasData.data[i + 3] >= 255) {
            // console.log("i: " + i + " data: ", canvasData.data[i]);
            // console.log(
            //   "r: ",
            //   canvasData.data[i],
            //   " g: ",
            //   canvasData.data[i + 1],
            //   "b: ",
            //   canvasData.data[i + 2],
            //   "a: ",
            //   canvasData.data[i + 3]
            // );
            // (x + y * this.canvas.width) * 4
            let y = i / 4 / this.canvas.width;

            // hasAlphaPixels = true;
            // console.log("found: " + y);
            this.correctY = y;
            // window.scrollY = window.scrollY - this.correctY;
            break;
          }
          // drawPixel(i, 0, 0, 255, 255, 255, 255);
          // this.context.putImageData(canvasData, 0, 0);
        }
        // let newBodyHeight = prevBodyHeight - this.correctY;
        // console.log("First pixel: 1", hasAlphaPixels);
        // this.$nextTick(() => {
        //   setTimeout(() => {
        //     // console.log(
        //     //   // window.scrollY,
        //     //   // document.body.offsetHeight,
        //     //   prevBodyHeight,
        //     //   document.body.offsetHeight,
        //     //   prevBodyHeight - document.body.offsetHeight
        //     //   // document.body.offsetHeight - window.scrollY
        //     // );
        //     // console.log(this.canvas.height - this.correctY, window.scrollY - prevCorrect + this.correctY);
        //     window.scrollTo({
        //       top: window.scrollY + prevCorrect - this.correctY,
        //       left: 0,
        //       behavior: "smooth",
        //     });
        //   }, 1);
        // });
      }
    },
    loadImages() {
      return new Promise((resolve) => {
        let count = 0;
        this.items.forEach((item) => {
          if (item.variant_id) {
            item.variants.forEach((variant) => {
              Object.keys(variant.layers).forEach((index) => {
                variant.layers[index].forEach(() => {
                  count += 1;
                });
              });
            });
          } else {
            Object.keys(item.layers).forEach((index) => {
              item.layers[index].forEach(() => {
                count += 1;
              });
            });
          }
        });
        let current = 0;
        this.items.forEach((item) => {
          if (item.variant_id) {
            item.variants.forEach((variant) => {
              Object.keys(variant.layers).forEach((index) => {
                variant.layers[index].forEach((l) => {
                  let loadImg = new Image(); // Создаёт новое изображение
                  loadImg.addEventListener(
                    "load",
                    function () {
                      current += 1;
                      l.image = loadImg;
                      if (current >= count) {
                        // console.log("Images loaded");
                        resolve();
                      }
                    },
                    false
                  );
                  loadImg.src = l.url; // Устанавливает источник файла
                });
              });
            });
          } else {
            Object.keys(item.layers).forEach((index) => {
              item.layers[index].forEach((l) => {
                let loadImg = new Image(); // Создаёт новое изображение
                loadImg.addEventListener(
                  "load",
                  function () {
                    current += 1;
                    l.image = loadImg;
                    if (current >= count) {
                      // console.log("Images loaded");
                      resolve();
                    }
                  },
                  false
                );
                loadImg.src = l.url; // Устанавливает источник файла
              });
            });
          }
        });
      });
    },
  },
  mounted() {
    let self = this;
    this.canvas = document.getElementById("canvas");
    this.context = this.canvas.getContext("2d");

    const Lazyload = inject("Lazyload");

    Lazyload.$on("loaded", function () {
      // if (self.load === false && el.className === "order-check") {
      self.load = true;
      self.loadImages().then(() => {
        self.updateCanvas();
      });
      // }
    });
  },
};
</script>

<style lang="scss" scoped>
canvas {
  //height: 90%;
  //padding-top: 10px;
  //padding-bottom: 10px;
}
</style>
